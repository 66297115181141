import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { FaChevronRight } from 'react-icons/fa';
import Layout from '../components/layout';
import Metadata from '../components/metadata';
import GenericContent from '../components/generic-content';

const InsightPage = ({ data }) => {
  const { mdx: page } = data;
  const { frontmatter: metadata, body } = page;

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <GenericContent
        image={metadata.image}
        title={metadata.title}
        body={body}
      >
        <Styled.a href="/perspectives" sx={{ gridColumn: 'content-start / content-end' }}>
          More insights
          <FaChevronRight sx={{ position: 'relative', top: '3px' }} />
        </Styled.a>
      </GenericContent>
    </Layout>
  );
};

InsightPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }).isRequired,
};

export default InsightPage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
